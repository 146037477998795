import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import intl from "react-intl-universal";
import { LocaleProvider } from 'antd-mobile'

import IntlPolyfill from "intl";
import Cookies from 'js-cookie'

import Index from '../pages/Index/index'
import en_US from 'antd-mobile/lib/locale-provider/en_US'
//universal国际化文件
const intl_locales = {
  "en-US": require("../locales/en_US.json"),
  "zh-CN": require("../locales/zh_CN.json"),
};

//antd国际化文件
const antd_locales = {
  // zh_CN: require("antd-mobile/lib/locale-provider/zh_CN"),
  // en_US: require("antd-mobile/lib/locale-provider/en_US"),
  zh_CN: undefined,
  en_US: en_US,
};

// For Node.js, common locales should be added in the application
global.Intl = IntlPolyfill;
require("intl/locale-data/jsonp/en.js");
require("intl/locale-data/jsonp/zh.js");

class App extends Component {
  componentWillMount() {
    if (!Cookies.get("lang")) {
      let lang = window.navigator.language;
      switch (lang) {
        case 'zh-CN':
        case 'zh':
          lang = 'zh-CN';
          break;
        default:
          lang = 'en-US';
          break;
      }
      Cookies.set("lang", lang, {
        expires: 7
      });
    }
    this.loadLocales();
  }
  loadLocales = () => {
    const lang = Cookies.get("lang");
    // init method will load CLDR locale data according to currentLocale
    // react-intl-universal is singleton, so you should init it only once in your app
    const currentLocale = intl_locales[lang];
    intl
      .init({
        currentLocale: lang, // TODO: determine locale here
        locales: {
          [lang]: currentLocale
        }
      })
      .then(() => {
        // After loading CLDR locale data, start to render
        // this.intl.lanFlag = !this.intl.lanFlag;
        //window.location.reload();
      });
  };
  render() {
    const antd_locale = Cookies.get("lang").replace("-", "_");
        const locale = antd_locales[antd_locale];
        return <div className="App">
            <LocaleProvider locale={locale}>
                <Router>
                    <Switch>
                      <Route path="/index" component={Index} />
                      <Route path="/*" render={(props) => <Redirect to='/Index' />} />
                    </Switch>
                </Router>
            </LocaleProvider>
        </div>
  }
}

export default App;
