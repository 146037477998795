import React, { Component } from 'react';
import Swiper from 'swiper/dist/js/swiper.js'
import Cookies from 'js-cookie'
import intl from 'react-intl-universal'
import 'swiper/dist/css/swiper.min.css'
import './index.scss'
import '../../style/reset.scss'



// import { Carousel, WingBlank, Tabs } from 'antd-mobile';
import Tabs from 'antd-mobile/lib/tabs'; // 加载 JS
import 'antd-mobile/lib/tabs/style/css';
import Accordion from 'antd-mobile/lib/accordion'; // 加载 JS
import 'antd-mobile/lib/accordion/style/css';
import Popover from 'antd/lib/popover';
import 'antd/lib/popover/style/css';
import Icon from 'antd/lib/icon';
import 'antd/lib/icon/style/css';

// import bg from '../../images/solution/solution_01_bg.png'

class Index extends Component {
    state = {
        data: ['1', '2', '3'],
        imgHeight: 176,
        tabIndex: 0
    }
    items = [
        {
            image: require("../../images/solution/solution_03_bg.png"),
            icon: require("../../images/solution/solution_03.png"),
            title: intl.get("Swiper_Title_Tab1"),
            desc: intl.get("Swiper_Desc_Tab1")
        }, 
        {
            image: require("../../images/solution/solution_02_bg.png"),
            icon: require("../../images/solution/solution_02.png"),
            title: intl.get("Swiper_Title_Tab2"),
            desc: intl.get("Swiper_Desc_Tab2")
        }, 
        {
            image: require("../../images/solution/solution_01_bg.png"),
            icon: require("../../images/solution/solution_01.png"),
            title: intl.get("Swiper_Title_Tab3"),
            desc: intl.get("Swiper_Desc_Tab3")
        }, 
        {
            image: require("../../images/solution/solution_05_bg.png"),
            icon: require("../../images/solution/solution_05.png"),
            title: intl.get("Swiper_Title_Tab4"),
            desc: intl.get("Swiper_Desc_Tab4")
        },
        {
            image: require("../../images/solution/solution_04_bg.png"),
            icon: require("../../images/solution/solution_04.png"),
            title: intl.get("Swiper_Title_Tab5"),
            desc: intl.get("Swiper_Desc_Tab5")
        }
    ];
    componentWillMount() {
        if (!Cookies.get('lang')) {
            let lang = window.navigator.language;
            if (lang === 'zh') lang = 'zh-CN';
            Cookies.set("lang", lang, { expires: 7 });
        }
        const lang = Cookies.get('lang');
        switch (lang) {
            case 'zh-CN':
            case 'zh': this.defaultLang = '简体中文'; break;
            case 'en-US': this.defaultLang = 'English'; break;
            default: this.defaultLang = 'English';
        }
    }
    componentDidMount() {
        this.mySwiper = new Swiper('.swiper-container', {
            // grabCursor: true,
            centeredSlides: true,
            watchSlidesProgress: true,
            // width: "200px",
            // loop:true,
            slidesPerView: 1.4,
            on: {
                // touchEnd: ()=>{
                //    console.log(this.mySwiper.activeIndex)
                // },
                slideChange: () => {
                    console.log(this.mySwiper.activeIndex);
                    this.setState({
                        tabIndex: this.mySwiper.activeIndex
                    })
                },
            },
            effect: 'coverflow', //3d滑动
            coverflowEffect: {
                rotate: 0,  //设置为0
                stretch: -20,
                depth: 90,
                modifier: 2,
                slideShadows: true,
            }
        });
        console.log(intl.options.currentLocale)
    }
    tabs2 = [
        { title: intl.get("Swiper_Title_Tab1"), sub: '1' },
        { title: intl.get("Swiper_Title_Tab2"), sub: '2' },
        { title: intl.get("Swiper_Title_Tab3"), sub: '3' },
        { title: intl.get("Swiper_Title_Tab4"), sub: '4' },
        { title: intl.get("Swiper_Title_Tab5"), sub: '5' },
    ];

    change = (e) => {
        console.log(e)
        this.mySwiper.slideTo(e, 1000, false)
    }
    onTabClick = (tab, index) => {
        console.log(tab, index)
        this.mySwiper.slideTo(index, 1000, false)
        this.setState({
            tabIndex: index
        })
    }
    onAccordion = (key) => {
        console.log(key);
    }
    renderLang = () => {
        return (
            <ul style={{ minWidth: 30,marginBottom: 0 }}>
                <li className='language-item'><span onClick={() => this.changeLanguage('zh-CN')} className='language-item-span'>简体中文</span></li>
                <li className='language-item'><span onClick={() => this.changeLanguage('en-US')} className='language-item-span'>English</span></li>
            </ul>
        )
    }
    changeLanguage = (e) => {
        Cookies.set('lang', e, { expires: 7 });
        window.location.reload();
    }
    renderContent = tab =>
        (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '150px', backgroundColor: '#fff' }}>
            <p>Content of {tab.title}</p>
        </div>);
    render() {
        return (
            <div className='index-page'>
                <div className="index-header clearfix">
                    <div className="logo">
                        <img style={{height: ".28rem"}} src={require("../../images/logo.svg")} alt=""/>
                    </div>
                    <div className="language-box-mobile">
                        <Popover
                            placement="bottom"
                            content={this.renderLang()}
                            trigger="click"
                            overlayClassName='popover-mobile'
                        >
                            <span className='current-lang'>{this.defaultLang}</span><Icon type="down" />
                        </Popover>
                    </div>
                </div>
                <div className="banner">
                    <div className="banner-title">{intl.get("Banner_Title")}</div>
                    <div className="banner-desc">
                        <div className="banner-desc-content">{intl.get("Banner_Desc")}</div>
                    </div>
                    <img className='banner-img' src={require("../../images/icon/banner_bg.svg")} alt=""/>
                </div>
                <div className="production-service-box">
                    <div className="title">{intl.get("Production_Service_Title")}</div>
                    <div className="desc">{intl.get("Production_Service_Desc")}</div>
                    <div className="content">
                        <Accordion accordion openAnimation={{}} className="my-accordion" onChange={this.onAccordion}>
                            <Accordion.Panel
                                header={
                                    <div className='custom-header'>
                                        <img alt='' className='accordion-icon' src={require("../../images/icon/Zig-Ledger@3x.png")} />
                                        <span className="pane-title">Z-Ledger</span>
                                        <span className="hot">{intl.get("Hot")}</span>
                                    </div>
                                }
                                className="pad"
                            >
                                {intl.get("ZigLedger_Desc")}
                            </Accordion.Panel>
                            <Accordion.Panel
                                header={
                                    <div className='custom-header'>
                                        <img alt='' className='accordion-icon' src={require("../../images/icon/Hyperledger@3x.png")} />
                                        <span className="pane-title">Hyperledger Fabric</span>
                                        <span className="hot">{intl.get("Hot")}</span>
                                    </div>
                                }
                                className="pad"
                            >
                                {intl.get("Fabric_Desc")}
                            </Accordion.Panel>
                            <Accordion.Panel 
                                header={
                                    <div className='custom-header'>
                                        <img alt='' className='accordion-icon' src={require("../../images/icon/ETH@3x.png")} />
                                        <span className="pane-title">ETH</span>
                                    </div>
                                }
                                className="pad"
                            >
                                {intl.get("ETH_Desc")}
                            </Accordion.Panel>
                            <Accordion.Panel 
                                header={
                                    <div className='custom-header'>
                                        <img alt='' className='accordion-icon' src={require("../../images/icon/IPFS@3x.png")} />
                                        <span className="pane-title">IRISnet</span>
                                    </div>
                                }
                                className="pad"
                            >
                                {intl.get("IRISnet_Desc")}
                            </Accordion.Panel>
                            <Accordion.Panel 
                                header={
                                    <div className='custom-header'>
                                        <img alt='' className='accordion-icon' src={require("../../images/icon/Formalization@3x.png")} />
                                        <span className="pane-title">{intl.get("Formal_Verification_Title")}</span>
                                    </div>
                                }
                                className="pad"
                            >
                                {intl.get("Formal_Verification_Desc")}
                            </Accordion.Panel>
                            <Accordion.Panel 
                                header={
                                    <div className='custom-header'>
                                        <img alt='' className='accordion-icon' src={require("../../images/icon/Oracle@3x.png")} />
                                        <span className="pane-title">Oracle</span>
                                    </div>
                                }
                                className="pad"
                            >
                                {intl.get("Oracle_Desc")} 
                            </Accordion.Panel>
                            <Accordion.Panel 
                                header={
                                    <div className='custom-header'>
                                        <img alt='' className='accordion-icon' src={require("../../images/icon/IRIS@3x.png")} />
                                        <span className="pane-title">IPFS</span>
                                    </div>
                                }
                                className="pad"
                            >
                                {intl.get("IPFS_Desc")}
                            </Accordion.Panel>
                        </Accordion>
                    </div>
                </div>
                <div className="swiper-box">
                    <div className="title">{intl.get("Enterprise_Solutions")}</div>
                    <div className="desc">{intl.get("Enterprise_Solutions_Desc")}</div>
                    <Tabs
                        tabs={this.tabs2}
                        initialPage={1}
                        page={this.state.tabIndex}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={2} />}
                        onTabClick={this.onTabClick}
                        tabBarBackgroundColor="#151f30"
                        tabBarInactiveTextColor="#9b9ea0"
                        tabBarActiveTextColor="#0094fe"
                        tabBarUnderlineStyle={{ background: '#0094fe'}}
                        // usePaged
                    >
                        {
                            // this.renderContent
                        }
                    </Tabs>
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            {
                                this.items.map((item, index) => {
                                    return <div style={{ backgroundImage: `url(${item.image})`,backgroundSize: "100% 100%"}} key={index} className='swiper-slide'>
                                        <div className="cover-floor">
                                            <img className='item-icon' src={item.icon} alt=""/>
                                            <div className="item-title">{item.title}</div>
                                            <p className='item-desc'>{item.desc}</p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="platform-advantages">
                    <div className="title">{intl.get("Platform_Advantage")}</div>
                    <div className="desc">{intl.get("Platform_Advantage_Desc")}</div>
                    <div className="advantage-row">
                        <div className="item">
                            <img style={{width: ".32rem"}} src={require("../../images/icon/icon_advantage_01@3x.png")} alt=""/>
                            <div className="desc">{intl.get("Platform_Item1")}</div>
                        </div>
                        <div className="item">
                            <img style={{ width: ".32rem" }} src={require("../../images/icon/icon_advantage_02@3x.png")} alt="" />
                            <div className="desc">{intl.get("Platform_Item2")}</div>
                        </div>
                        <div className="item">
                            <img style={{ width: ".32rem" }} src={require("../../images/icon/icon_advantage_03@3x.png")} alt="" />
                            <div className="desc">{intl.get("Platform_Item3")}</div>
                        </div>
                    </div>
                    <div className="advantage-row">
                        <div className="item">
                            <img style={{ width: ".32rem" }} src={require("../../images/icon/icon_advantage_04@3x.png")} alt="" />
                            <div className="desc">{intl.get("Platform_Item4")}</div>
                        </div>
                        <div className="item">
                            <img style={{ width: ".32rem" }} src={require("../../images/icon/icon_advantage_05@3x.png")} alt="" />
                            <div className="desc">{intl.get("Platform_Item5")}</div>
                        </div>
                        <div className="item">
                            <img style={{ width: ".32rem" }} src={require("../../images/icon/icon_advantage_06@3x.png")} alt="" />
                            <div className="desc">{intl.get("Platform_Item6")}</div>
                        </div>
                    </div>
                </div>
                <div className="partner-box">
                    <div className="title">{intl.get("Partner")}</div>
                    <div className="partner-row">
                        <img className='partner-icon' src={require("../../images/icon/partner01@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner02@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner03@3x.png")} alt=""/>
                    </div>
                    <div className="partner-row">
                        <img className='partner-icon' src={require("../../images/icon/partner04@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner05@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner06@3x.png")} alt="" />
                    </div>
                    <div className="partner-row">
                        <img className='partner-icon' src={require("../../images/icon/partner07@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner08@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner09@3x.png")} alt="" />
                    </div>
                    <div className="partner-row">
                        <img className='partner-icon' src={require("../../images/icon/partner10@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner11@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner12@3x.png")} alt="" />
                    </div>
                    <div className="partner-row">
                        <img className='partner-icon' src={require("../../images/icon/partner13@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner14@3x.png")} alt="" />
                        <img className='partner-icon' src={require("../../images/icon/partner15@3x.png")} alt="" />
                    </div>
                </div>
                <div className="travel-box">
                    <div className="title">{intl.get("Mobile_Journey")}</div>
                    <div className="step">
                        <div className="item">
                            <div className="img"><img src={require("../../images/icon/Computer_login@3x.png")} alt=""/></div>
                            <div className="text-box">
                                <div className="item-title">{intl.get("Mobile_Journey_Item1_Title")}</div>
                                <div className="item-desc">{intl.get("Mobile_Journey_Item1_Desc")}</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img"><img src={require("../../images/icon/Select_service@3x.png")} alt=""/></div>
                            <div className="text-box">
                                <div className="item-title">{intl.get("Mobile_Journey_Item2_Title")}</div>
                                <div className="item-desc">{intl.get("Mobile_Journey_Item2_Desc")}</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img"><img src={require("../../images/icon/Deployment_network@3x.png")} alt=""/></div>
                            <div className="text-box">
                                <div className="item-title">{intl.get("Mobile_Journey_Item3_Title")}</div>
                                <div className="item-desc">{intl.get("Mobile_Journey_Item3_Desc")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-box">
                    <div className="footer-content">
                        <div className="qr-code">
                            <img src={require("../../images/icon/qr_code.png")} alt=""/>
                        </div>
                        <div className={intl.options.currentLocale === "zh-CN"?"qr-code-text-zh":"qr-code-text-en"}>{intl.get("Mobile_Wechat")}</div>
                        <div className="tel">
                            <a href="tel:029-87565620" className="tel-text">
                                {intl.get("Tel")}：029-8756 5620
                            </a>
                        </div>
                        <div className="email">
                            <a href="mailto:info@zhigui.com" className="email-text">
                                E-mail：info@zhigui.com
                            </a>
                        </div>
                        <div className="footer-logo">
                            <img src={require("../../images/logo.svg")} alt=""/>
                        </div>
                        <div className="footer-text">Copyright © 2018  | {intl.get("Mobile_Company")} </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;